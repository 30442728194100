import { ChangeDetectionStrategy, Component, computed, input, OnInit, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Comment } from '../../comment';
import { CommentComponent } from '../comment/comment.component';

@Component({
  standalone: true,
  selector: 'smw-thread',
  templateUrl: './thread.component.html',
  styleUrls: ['./thread.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, CommentComponent],
})
export class ThreadComponent implements OnInit {
  comments = input.required<Comment[]>();

  showAll = signal(false);

  first = computed(() => this.comments()[0]);
  last = computed(() => this.comments()[this.comments().length - 1]);
  count = computed(() => this.comments().length - 2);
  isEmpty = computed(() => this.comments().length === 0);

  ngOnInit(): void {
    if (this.comments().length < 4) {
      this.showAll.set(true);
    }
  }
}
