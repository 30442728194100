import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  Inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject, takeUntil } from 'rxjs';

import { ANALYTICS, Analytics, EventIdentifier } from '@smw/analytics-front';
import { JobResponsibility, User } from '@smw/common-models';
import { BusinessServiceDto } from '@smw/common-rest-dtos';
import { RoutePipe } from '@smw/navigation-front';
import { UserStore } from '@smw/stores-front';
import {
  ButtonComponent,
  IconComponent,
  PasswordComponent,
  SelectComponent,
  SelectOption,
  TextFieldComponent,
} from '@smw/ui-front';
import { PluginsService } from '@smw/utils-front';

import { AuthTemplateComponent } from '../../components/template.component';
import { passwordRegexp } from '../../helpers/password-strengh';
import { CallbackURI, CallbackUrlService } from '../../services/callback-url.service';
import { RegisterService } from '../../services/register.service';

type RegisterForm = FormGroup<{
  email: FormControl<string>;
  firstname: FormControl<string>;
  lastname: FormControl<string | null>;
  password: FormControl<string>;
  rgpd: FormControl<boolean>;
  newsletterConsent: FormControl<boolean>;
  businessService: FormControl<string | null>;
  jobResponsabilities: FormControl<string | null>;
}>;

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    TranslateModule,
    ButtonComponent,
    TextFieldComponent,
    PasswordComponent,
    SelectComponent,
    IconComponent,
    AuthTemplateComponent,
    RoutePipe,
  ],
  providers: [RegisterService, CallbackUrlService],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit, OnDestroy {
  form!: RegisterForm;

  isConfigLoaded = false;
  businessServiceOptions: SelectOption<BusinessServiceDto>[] = [];
  jobResponsibilitiesOptions: SelectOption<string>[] = [];

  isBusinessServiceDisplayed = false;
  isJobResponsibilitiesDisplayed = false;
  isInvalid = signal(true);
  isEmailInvalid = signal(false);
  isLoading = signal(false);
  formRequestErrorMessage = signal('');

  hasFormRequestError = computed(() => this.formRequestErrorMessage().length > 0);
  isConfirmDisabled = computed(() => this.isLoading() || this.isInvalid());
  showSuccessModal = signal(false);
  userFirstName = computed(() => this.userStore.firstname());

  clientCode: string | undefined;
  callbackURI!: CallbackURI;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private callbackUrlService: CallbackUrlService,
    private registerService: RegisterService,
    private pluginsService: PluginsService,
    private userStore: UserStore,
    @Inject(ANALYTICS) private analytics: Analytics,
  ) {}

  ngOnInit(): void {
    this.callbackURI = this.callbackUrlService.getCallbackUrl('/');
    this.clientCode = this.route.snapshot.queryParams['client'];
    this.changeDetector.markForCheck();

    combineLatest([
      this.pluginsService.containPlugin('b2c'),
      this.registerService.getBusinessServices(this.clientCode),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([hasB2COptions, businessServices]) => {
        if (businessServices.length) {
          this.initBusinessServices(businessServices);
        }

        if (hasB2COptions) {
          this.initJobResponsibilities();
        }

        this.isConfigLoaded = true;
        this.changeDetector.markForCheck();
      });

    this.form = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email, this.validateEmailDomain()],

        nonNullable: true,
      }),
      firstname: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      lastname: new FormControl<string | null>(null),
      password: new FormControl('', {
        validators: [Validators.required, Validators.pattern(passwordRegexp)],
        nonNullable: true,
      }),
      rgpd: new FormControl<boolean>(false, {
        validators: [Validators.requiredTrue],
        nonNullable: true,
      }),
      businessService: new FormControl<string | null>(null),
      jobResponsabilities: new FormControl<string | null>(null),
      newsletterConsent: new FormControl(false, { nonNullable: true }),
    });

    this.form.statusChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => this.isInvalid.set(status === 'INVALID'));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initBusinessServices(services: BusinessServiceDto[]): void {
    this.businessServiceOptions = services.map((item) => ({
      value: item,
      label: item.name,
    }));
    this.isBusinessServiceDisplayed = true;
    const businessServiceControl = this.form.get('businessService');
    businessServiceControl?.addValidators(Validators.required);
  }

  private initJobResponsibilities(): void {
    this.isJobResponsibilitiesDisplayed = true;
    const jobResponsabilitiesControl = this.form.get('jobResponsabilities');
    jobResponsabilitiesControl?.addValidators(Validators.required);

    this.jobResponsibilitiesOptions = [
      {
        label: this.translate.instant('job-responsabilitie-manager'),
        value: this.translate.instant('job-responsabilitie-manager'),
      },
      {
        label: this.translate.instant('job-responsabilitie-rh'),
        value: this.translate.instant('job-responsabilitie-rh'),
      },
      {
        label: this.translate.instant('job-responsabilitie-coach'),
        value: this.translate.instant('job-responsabilitie-coach'),
      },
      {
        label: this.translate.instant('job-responsabilitie-nothing'),
        value: this.translate.instant('job-responsabilitie-nothing'),
      },
    ];
  }

  submit(): void {
    this.formRequestErrorMessage.set('');

    if (this.form.invalid) {
      return;
    }

    const formValue = this.form.getRawValue();
    this.isLoading.set(true);

    this.registerService
      .register({
        firstname: formValue.firstname,
        lastname: formValue.lastname ?? undefined,
        email: formValue.email,
        password: formValue.password,
        jobResponsabilities: formValue.jobResponsabilities ?? undefined,
        businessService: formValue.businessService ?? undefined,
        newsletterConsent: formValue.newsletterConsent,
        client: this.clientCode,
      })
      .subscribe({
        next: (user) => {
          this.isLoading.set(false);
          if (user) {
            this.track(user);
            this.showSuccessModal.set(true);
          } else {
            this.formRequestErrorMessage.set(marker('auth_register_error'));
          }
        },
        error: () => {
          this.isLoading.set(false);
          this.formRequestErrorMessage.set(marker('auth_register_error'));
        },
      });
  }

  validateEmailDomain(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      this.isEmailInvalid.set(false);
      const pattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      let email = control.value as string;
      email = email.replace(' ', '');

      if (email.length === 0) {
        return null;
      }

      const isValid = pattern.test(email);

      this.isEmailInvalid.set(!isValid);

      return !isValid ? { invalidDomain: { value: control.value } } : null;
    };
  }

  onRedirect(): void {
    this.callbackUrlService.clear();
  }

  private track(user: User): void {
    this.analytics.identify(user.id, {
      email: user.email,
      tenant: user.client?.code ?? 'undefined',
      roles: ['user'],
      firstname: user.firstname,
      lastname: user.lastname ?? '',
      businessService: user.businessServiceId ?? '',
      jobResponsabilities: user.jobResponsabilities ?? JobResponsibility.OTHER,
      newsletterConsent: user.newsletterConsent ?? false,
      clientId: user.client?.id ?? '',
      clientName: user.client?.name ?? '',
      clientActive: user.client?.active ?? true,
      isRealUser: user.isRealUser ?? true,
    });

    this.analytics.track({ id: EventIdentifier.UserSignedUp });
  }
}
