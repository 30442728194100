import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  TemplateRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CheckboxComponent } from '../form';
import { Overlay } from '../overlay/overlay';

export type DropdownItem<T> = {
  value: T;
  label?: string;
  disabled?: boolean;
};

@Component({
  selector: 'smw-dropdown',
  standalone: true,
  imports: [CommonModule, TranslateModule, CheckboxComponent, FormsModule],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent<T> {
  options = input.required<DropdownItem<T>[]>();
  multiple = input(false);
  selectedOptions = input<Array<T | T[keyof T]> | Set<T | T[keyof T]>>();
  comparisonKey = input<keyof T>();
  customItemLabelTemplate = input<TemplateRef<unknown>>();
  emptyMessage = input<string>();

  itemSelected = output<T>();
  closed = output();

  isEmpty = computed(() => this.options().length === 0);

  constructor(private overlay: Overlay) {}

  isChecked(option: DropdownItem<T>): boolean {
    const selectedOptions = this.selectedOptions();
    if (!selectedOptions) {
      return false;
    }

    const comparisonKey = this.comparisonKey();

    if (selectedOptions instanceof Set) {
      if (selectedOptions.size === 0) {
        return false;
      }

      return selectedOptions.has(comparisonKey ? option.value[comparisonKey] : option.value);
    } else {
      if (!selectedOptions.length) {
        return false;
      }

      return selectedOptions.some((item) =>
        comparisonKey ? item === option.value[comparisonKey] : item === option.value,
      );
    }
  }

  onClick(selection: T, event: MouseEvent): void {
    event.stopImmediatePropagation();

    this.itemSelected.emit(selection);

    if (!this.multiple()) {
      this.overlay.destroy();
    }
  }
}
