import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { AvatarComponent } from '../../../avatar/avatar.component';
import { Comment } from '../../comment';

@Component({
  standalone: true,
  selector: 'smw-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, AvatarComponent],
})
export class CommentComponent {
  comment = input.required<Comment>();

  fullname = computed(() => this.comment().author.fullname);
  initials = computed(() => this.fullname()?.[0].toUpperCase() ?? '');
  content = computed(() => this.comment().content);
  datetime = computed(() => DateTime.fromISO(this.comment().date));
  date = computed(() => this.datetime().toFormat('dd/MM/yy'));
  time = computed(() => this.datetime().toFormat("HH'h'mm"));
}
