import { CommonModule, Location } from '@angular/common';
import { Component, input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '@smw/ui-front';

import { HistoryService } from './history.service';
import { NavService } from './nav.service';
@Component({
  selector: 'smw-back',
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonComponent],
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.less'],
})
export class BackComponent {
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private nav: NavService,
    private history: HistoryService,
  ) {}

  default = input<string>();
  override = input<string>();

  back() {
    const override = this.override();

    if (override) {
      this.router.navigateByUrl(override);
    } else if (this.history.isEmpty()) {
      if (!this.default()) {
        this.router.navigate(['..'], { relativeTo: this.route });
      } else {
        this.router.navigateByUrl(this.nav.routeOf('collaborator'));
      }
    } else {
      console.log('has history');
      this.location.back();
    }
  }
}
