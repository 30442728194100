<smw-background />

<main>
  <h1 class="title-lg text-primary-500">{{ title() }}</h1>

  @if (message()) {
    <p class="body-lg text-center" [innerHTML]="message()"></p>
  }

  <button smw-button kind="filled" (click)="cta.emit()">{{ ctaLabel() }}</button>

  @if (secondaryCTALabel(); as label) {
    <button smw-button kind="terniary" (click)="secondaryCTA.emit()">
      {{ label }}
    </button>
  }
</main>

<smw-rain-w />
