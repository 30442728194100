<div class="backdrop" (click)="closed.emit()"></div>
<div class="overlay" @slideIn>
  <header>
    <div class="actions">
      <button type="button" (click)="closed.emit()">
        <smw-icon icon="close"></smw-icon>
      </button>
    </div>
    <p class="title-lg text-white mb-0">
      <ng-content select="[title]"></ng-content>
    </p>
  </header>

  <div class="content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
