import { InjectionToken } from '@angular/core';

import { JobResponsibility } from '@smw/common-models';

import { AnalyticsEvent, EventId } from './events';

export const ANALYTICS = new InjectionToken<Analytics>('Analytics');

export interface Analytics {
  identify(userId: string, userProfile: AnalyticsUserProfile): void;
  disconnectIdentity(): void;
  track(event: AnalyticsEvent, userProfile?: AnalyticsUserProfile | undefined): void;
  startTimer(trackedEventId: EventId): void;
}

export interface AnalyticsUserProfile {
  email: string;
  tenant: string;
  firstname: string;
  lastname: string;
  roles: string[];
  businessService: string;
  jobResponsabilities: JobResponsibility;
  newsletterConsent: boolean;
  clientId: string;
  clientName: string;
  clientActive: boolean;
  isRealUser: boolean;
}
