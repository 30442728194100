/* eslint-disable @angular-eslint/component-selector */
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, model } from '@angular/core';

import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'smw-collapse, [collapse]',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('collapse', [
      state('true', style({ height: '0' })),
      state('false', style({ height: '*' })),
      transition('true <=> false', animate('{{duration}}ms {{animation}}'), {
        params: {
          duration: 250,
          animation: 'ease-in',
        },
      }),
    ]),
  ],
})
export class CollapseComponent {
  collapsed = model(true);
}
