@if (isEmpty()) {
  <p class="body-md empty">{{ 'comments_empty_message' | translate }}</p>
} @else {
  @if (showAll()) {
    @for (comment of comments(); track comment.id) {
      <smw-comment [comment]="comment" />
    }
  } @else {
    <smw-comment [comment]="first()" />
    <button type="button" class="caption-xs show-all" (click)="showAll.set(true)">
      {{ 'comments_show_all' | translate: { count: count() } }}
    </button>
    <smw-comment [comment]="last()" />
  }
}
