/* eslint-disable @angular-eslint/directive-selector */
import { BreakpointObserver } from '@angular/cdk/layout';
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { Breakpoint, toMediaQuery } from '../helpers/breakpoints';

@Directive({ selector: '[breakpoints]', standalone: true })
export class BreakpointDirective implements OnInit, OnDestroy {
  @Input() breakpoints!: Breakpoint[];
  @Input() breakpointOnly = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private templateRef: TemplateRef<Element>,
    private vcr: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(toMediaQuery(this.breakpoints, this.breakpointOnly))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result.matches) {
          this.vcr.createEmbeddedView(this.templateRef);
        } else {
          this.vcr.clear();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
