import {
  ChangeDetectionStrategy,
  Component,
  computed,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { RoutePipe } from '@smw/navigation-front';
import { UserStore } from '@smw/stores-front';
import {
  ButtonComponent,
  IconComponent,
  PasswordComponent,
  SuccessPageComponent,
} from '@smw/ui-front';

import { AuthTemplateComponent } from '../../components/template.component';
import { passwordRegexp } from '../../helpers/password-strengh';
import { AuthenticationService } from '../../services';
import { RegisterService } from '../../services/register.service';

type RegisterForm = FormGroup<{
  password: FormControl<string>;
  rgpd: FormControl<boolean>;
  newsletterConsent: FormControl<boolean>;
}>;

@Component({
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    TranslateModule,
    ButtonComponent,
    PasswordComponent,
    IconComponent,
    AuthTemplateComponent,
    RoutePipe,
    SuccessPageComponent,
  ],
  providers: [RegisterService],
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivationComponent implements OnInit, OnDestroy {
  activationToken: WritableSignal<string> = signal('');
  showSuccessModal = signal(false);

  form: RegisterForm = new FormGroup({
    password: new FormControl('', {
      validators: [Validators.required, Validators.pattern(passwordRegexp)],
      nonNullable: true,
    }),
    rgpd: new FormControl<boolean>(false, {
      validators: [Validators.requiredTrue],
      nonNullable: true,
    }),
    newsletterConsent: new FormControl(false, { nonNullable: true }),
  });

  isInvalid = signal(true);
  isLoading = signal(false);
  formRequestErrorMessage = signal('');

  hasFormRequestError = computed(() => this.formRequestErrorMessage().length > 0);
  isConfirmDisabled = computed(() => this.isLoading() || this.isInvalid());

  userFirstName = computed(() => this.userStore.firstname());

  private unsubscribe$ = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private userStore: UserStore,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((url) => {
      this.activationToken.set(url[url.length - 1].path);
    });

    this.form.statusChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => this.isInvalid.set(status === 'INVALID'));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submit(): void {
    this.formRequestErrorMessage.set('');

    if (this.form.invalid) {
      return;
    }

    const formValue = this.form.getRawValue();
    this.isLoading.set(true);

    this.authService
      .activate({
        activationToken: this.activationToken(),
        password: formValue.password,
        newsletterConsent: formValue.newsletterConsent,
      })
      .subscribe({
        next: () => {
          this.showSuccessModal.set(true);
        },
        error: () => {
          this.isLoading.set(false);
          this.formRequestErrorMessage.set(marker('auth_activation_error'));
        },
      });
  }
}
