<smw-avatar [fullname]="fullname()" [adaptative]="true" />
<div class="wrapper">
  <div class="info">
    <span class="title-xxs fullname">{{ fullname() }}</span>
    <span class="divider"></span>
    <span class="body-xs datetime">{{
      'objectives_comment_datetime' | translate: { date: date(), time: time() }
    }}</span>
  </div>
  <p class="body-sm content">{{ content() }}</p>
</div>
