<textarea
  #textarea
  type="text"
  class="body-sm"
  [placeholder]="placeholder() ?? ('comment_input_placeholder' | translate)"
  [disabled]="disabled()"
  (input)="onInput()"
  (keyup.enter)="send()"
></textarea>
<button smw-button kind="icon" icon="send" [class.disabled]="disabled()" (click)="send()"></button>
