import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '../icon/icon.component';

@Component({
  standalone: true,
  selector: 'smw-banner',
  templateUrl: 'banner.component.html',
  styleUrls: ['banner.component.less'],
  imports: [IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {}
