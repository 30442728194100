/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { APP_ROUTES } from './routes';

/**
 * Interpolate a path into a route with the correct params
 */
@Injectable({ providedIn: 'root' })
export class NavService {
  // @ts-ignore
  routeOf(
    path: ExtractPaths<typeof APP_ROUTES>,
    params?: ExtractParams<ExtractRoute<ExtractPaths<typeof APP_ROUTES>, typeof APP_ROUTES>>,
  ): string {
    const route = this.extractRoute(path, APP_ROUTES);
    if (!route) {
      throw new Error(`Attempt to navigate to an undefined route ${path}`);
    }

    return route
      .split('/')
      .map((part) => {
        if (part.startsWith(':')) {
          const paramName = part.slice(1);
          // @ts-ignore
          return params?.[paramName] ?? part;
        }
        return part;
      })
      .join('/');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private extractRoute(path: string, route?: any | undefined): string {
    if (!route) {
      return '';
    }

    const [part, ...rest] = path.split('.');
    const node = route[part];

    if (!node) {
      return '';
    }

    if (rest.length === 0) {
      /** Failsafe in case of 'base' part included in the path. `base` should never be a part of the path */
      if (path === 'base') {
        return '';
      }

      return typeof node === 'string' ? `/${node}` : `/${node.base ?? ''}`;
    }

    /**
     * This cannot happen, the only time node can be a string is if rest is empty.
     * This condition is already handled just above. This check is only to prevent typescript from complaining
     */
    if (typeof node === 'string') {
      return '';
    }

    const child = this.extractRoute(rest.join('.'), node);

    /** A path with an undefined child element must be rejected entirely */
    if (!child.length) {
      return '';
    }

    return node.base ? `/${node.base}${child}` : `${child}`;
  }
}

/**
 * Extract all paths from APP_ROUTES using dot notation.
 *
 * @example
 * Given APP_ROUTES is {
 *   parent: {
 *     base: 'home',
 *     featA: 'featureA',
 *     featB: {
 *       base: 'featureB',
 *       child: {
 *         base: ':id',
 *         sectionA: 'summary',
 *         sectionB: ':sub_id'
 *       }
 *     }
 *   }
 * }
 *
 * ExtractPaths<APP_ROUTES> returns
 * | 'parent'
 * | 'parent.featA'
 * | 'parent.featB'
 * | 'parent.featB.child'
 * | 'parent.featB.child.sectionA'
 * | 'parent.featB.child.sectionB';
 */
export type ExtractPaths<T, Prefix extends string = ''> = T extends { base: string }
  ? Prefix | ExtractPaths<Omit<T, 'base'>, Prefix>
  : T extends Record<string, unknown>
    ? {
        [K in keyof T]: K extends 'base'
          ? never
          :
              | `${Prefix}${Prefix extends '' ? '' : '.'}${K & string}`
              | ExtractPaths<T[K], `${Prefix}${Prefix extends '' ? '' : '.'}${K & string}`>;
      }[keyof T]
    : never;

/**
 * Extract all routes from APP_ROUTES
 *
 * @example
 * Given APP_ROUTES is {
 *   parent: {
 *     base: 'home',
 *     featA: 'featureA',
 *     featB: {
 *       base: 'featureB',
 *       child: {
 *         base: ':id',
 *         sectionA: 'summary',
 *         sectionB: ':sub_id'
 *       }
 *     }
 *   }
 * }
 *
 * ExtractRoutes<APP_ROUTES> returns
 * | '/home'
 * | '/home/featureA'
 * | '/home/featureB'
 * | '/home/featureB/:id'
 * | '/home/featureB/:id/summary'
 * | '/home/featureB/:id/:sub_id'
 */
export type ExtractRoutes<T, Prefix extends string = ''> = T extends { base: string }
  ? `${Prefix}/${T['base']}` | ExtractRoutes<Omit<T, 'base'>, `${Prefix}/${T['base']}`>
  : T extends Record<string, unknown>
    ? {
        [K in keyof T]: T[K] extends string ? `${Prefix}/${T[K]}` : ExtractRoutes<T[K], Prefix>;
      }[keyof T]
    : never;

/**
 * Extract a specific route from APP_ROUTES given a path in dot notation
 *
 * @example
 * Given APP_ROUTES is {
 *   parent: {
 *     base: 'home',
 *     featA: 'featureA',
 *     featB: {
 *       base: 'featureB',
 *       child: {
 *         base: ':id',
 *         sectionA: 'summary',
 *         sectionB: ':sub_id'
 *       }
 *     }
 *   }
 * }
 *
 * ExtractRoute<'parent', APP_ROUTES> -> '/home'
 * ExtractRoute<'parent.featA', APP_ROUTES> -> '/home/featureA'
 * ExtractRoute<'parent.featB', APP_ROUTES> -> '/home/featureB'
 * ExtractRoute<'parent.featB.child', APP_ROUTES> -> '/home/featureB/:id'
 * ExtractRoute<'parent.featB.child.sectionA', APP_ROUTES> -> '/home/featureB/:id/summary'
 * ExtractRoute<'parent.featB.child.sectionB', APP_ROUTES> -> '/home/featureB/:id/:sub_id'
 */
export type ExtractRoute<
  Path extends string,
  T,
  Prefix extends string = '',
> = Path extends `${infer Parent}.${infer Child}`
  ? Parent extends keyof T
    ? {
        [K in keyof T[Parent]]: K extends 'base'
          ? ExtractRoute<Child, Omit<T[Parent], 'base'>, `${Prefix}/${T[Parent][K] & string}`>
          : never;
      }[keyof T[Parent]]
    : never
  : Path extends keyof T
    ? T[Path] extends string
      ? `${Prefix}/${T[Path] & string}`
      : {
          [K in keyof T[Path]]: K extends 'base' ? `${Prefix}/${T[Path][K] & string}` : never;
        }[keyof T[Path]]
    : never;

/**
 * Infer params object type from a route
 *
 * @example
 * ExtractParams<'/home/featureB'> -> never
 * ExtractParams<'/home/featureB'> -> never
 * ExtractParams<'/home/featureB/:id'> -> { id: string }
 * ExtractParams<'/home/featureB/:id/summary'> -> { id: string }
 * ExtractParams<'/home/featureB/:id/:sub_id'> -> { id: string; sub_id: string }
 */
export type ExtractParams<Route extends string> =
  Route extends `${infer _}:${infer Param}/${infer Rest}`
    ? { [K in Param & keyof ExtractParams<`/${Rest}`>]: string }
    : Route extends `${infer _}:${infer Param}`
      ? { [K in Param]: string }
      : never;
