<header [class.open]="!collapsed()">
  <ng-content select="[collapse-title]"></ng-content>
  <button
    smw-button
    kind="icon"
    [icon]="collapsed() ? 'carretDown' : 'carretUp'"
    (click)="collapsed.set(!collapsed())"
    class="collapse-trigger"
  ></button>
</header>

<div class="collapsible" [@collapse]="collapsed() ? true : false">
  <div class="body">
    <ng-content select="[collapse-body]"></ng-content>
  </div>
</div>
