import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import posthog from 'posthog-js';
import { Subject } from 'rxjs';

import { AnalyticDto } from '@smw/common-rest-dtos';

import { Analytics, AnalyticsUserProfile } from './analytics';
import { AnalyticsEvent, EventIdentifier } from './events';

@Injectable({
  providedIn: 'root',
})
export class PosthogAnalytics implements Analytics, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  startTimer(trackedEventId: string): void {
    trackedEventId;
    //console.log(trackedEventId);
    // throw new Error('Method not implemented.');
  }

  identify(userId: string, userProfile: AnalyticsUserProfile): void {
    posthog?.identify(userId, {
      // We can't deep copy user data as we run the risk of sending really sensitive data to the events, which is potentially accessible from anyone with posthog access
      // We should review which non root field of the userProfile should be embedeed in the event data : https://www.notion.so/somanyways/fix-posthog-add-nested-user-data-ce18fa675f9d46d2bcccb485eadfb28b?pvs=4
      ...userProfile,
    });

    const { clientId, clientName, clientActive } = userProfile;

    if (clientId && clientName) {
      // Company is the more common terminology, used in most saas products.
      // There are already ongoing discussion to rename clients to company, as these data are really hard to modify on posthog
      // Im already preparing the rename here.
      posthog?.group('company', clientId, {
        name: clientName,
        active: clientActive,
      });
    }
  }

  disconnectIdentity(): void {
    posthog?.reset();
  }

  async track(event: AnalyticsEvent, userProfile: AnalyticsUserProfile | undefined) {
    // It seem that currently these do NOT fire at first page load / last page exit when opening or closing window.
    // Posthog is currently setup to track them manually.
    // If this is fixed, we should DISABLE posthog automatic pageview/page exit tracking
    // https://www.notion.so/somanyways/fix-analytics-not-properly-tracking-first-page-view-exit-page-760687eea04843bebc51920f827d9fe4?pvs=4
    if (event.id === EventIdentifier.PageOpened) {
      posthog?.capture('$pageview');
    } else if (event.id === EventIdentifier.PageClosed) {
      posthog?.capture('$pageleave');
    } else {
      // Basic behavior events are already traccked by posthog automatically
      // Todo : review if sending all event is acceptable, it seem like potentially too much / a potential security issue.
      if (!event.data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        event.data = {} as any;
      }

      if (userProfile) {
        // We cast to any so we add the full user profile directly on the event data
        // The current typing of event data makes it quite difficult to add a field to each type of the union
        // We cannot send all data as json embedded object because it's not readily usable to filter in the UI if sent like that
        // TODO : check if would still be useful to embed the full userProfile
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>event.data).companyActive = userProfile.clientActive;
      }
      const backendEvent = JSON.parse(JSON.stringify(event));
      if (!backendEvent.data) {
        backendEvent.data = {};
      }
      backendEvent.data['$current_url'] = window.location.href;
      backendEvent.data['$pathname'] = this.router.url;

      await this.http
        .post<AnalyticDto>('/api/hi/event', backendEvent, {
          withCredentials: true,
        })
        .subscribe();
      // Second, we also capture the same event from the frontend, as it recommand by analytics gathering best practices https://posthog.com/docs/getting-started/send-events?#when-should-i-send-events-from-the-server-vs-the-client
      // We prepend them with "front-" to make them easily distinguishable from default event. Source of truth is the backend events, which is why they are the non-postponned ones
      // We cast to any here because the union type used in event declaration makes it very hard to make the type acceptable for posthog.capture method.
      // Also in essence, its meant to polymorphic data, so we dont introduce type safety risk here
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      posthog.capture(`front-${event.id}`, <any>event.data);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
