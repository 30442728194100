export const ClientPlugins = {
  workoscope: 'workoscope',
  interview: 'interview',
  b2c: 'b2c',
  synthese: 'synthese',
  mobility: 'mobility',
  ai: 'ai',
  formations: 'formations',
  hrdashboard: 'hrdashboard',
  bohrusers: 'bohrusers',
  bohrcampaigns: 'bohrcampaigns',
  bohrevents: 'bohrevents',
} as const;

export type ClientPlugins = (typeof ClientPlugins)[keyof typeof ClientPlugins];
